body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* animatie */

[data-aos="animate"].aos-animate {
 
  animation:   slideLeft 2s;
}


/* slider */

.triunghi{
  width: 0;
  height: 0;
  border-left: 200px solid rgba(3, 3, 3, 0.9);
  border-right: 350px solid transparent;
  border-top:  50vw solid rgba(3, 3, 3, 0.9);
  border-bottom: 30px solid transparent;
  
}

.triunghi-content {
  width: 300px;
  position: absolute;
  top: 10%;/* Setează poziția în interiorul triunghiului */
  left: 3%;
 /* Ajustează vertical pentru al centra */
}

.animate-slider1 {

  animation:   fadeUp 2s forwards;
 
 
}

/* ------------------- */

@keyframes fadeUp {
  0% {
    opacity: 0;
   /* Poți ajusta distanța verticală așa cum dorești */
  }
  100% {
    opacity: 1;
   
  }
}

@keyframes slideLeft {
  0% { 
      transform: translateX(-100%);
      opacity: 0;
  }
  100% { 
      transform: translateX(0);
      opacity: 1;
  }
}



.custom-border {
  border-bottom: 4px solid red; /* sau culoarea pe care o dorești */
}

/* beneficii */
.benefit__bg {
  background-size: cover;
  background-position: center;
  height: 350px;
  border-radius: 50% 2rem 2rem 2rem;
}

@media (min-width: 1200px) {
  .benefit__bg {
      height: 450px;
      border-radius: 2rem 50% 2rem 2rem;
  }
}

.benefit {
  padding: 1.5rem;
  background-color: transparent;
  border: 1px solid red;
  margin-bottom: 1.5rem;
  height: 280px;

  
  border-radius: 20px;
}

.benefit .benefit__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background:  rgba(3, 3, 3, 0.3);
}

.benefit .benefit__icon img {
  height: 35px;
  width: 53px;
  -o-object-fit: contain;
  object-fit: contain;
}

.benefit .benefit__nr {
  font-size: 4rem;
  font-weight: 800;
  -webkit-text-stroke: 1px red;
  -webkit-text-fill-color: red;
}

.benefit .benefit__name {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .benefit .benefit__name {
      font-size: 1.3rem;
  }
}

.benefit .benefit__brief {
  font-size: .9rem;
  color: #6c757d;
}

.benefit-banner .banner-left,
.benefit-banner .banner-right {
  background-size: cover;
  background-position: center;
  height: 250px;
}

@media (min-width: 768px) {
  .benefit-banner .banner-left,
  .benefit-banner .banner-right {
      height: 290px;
  }
}

@media (min-width: 1200px) {
  .benefit-banner .banner-left,
  .benefit-banner .banner-right {
      height: 350px;
  }
}

.benefit-banner .banner-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.5rem;
  color: #fff;
  overflow: hidden;
  z-index: 1;
  font-weight: 300;
  text-align: right;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .benefit-banner .banner-left {
      padding: 2rem;
  }
}

.benefit-banner .banner-left span {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.benefit-banner .banner-left:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: -95px;
  height: 100%;
  width: 77%;
  background: rgba(17, 15, 19, 0.6);
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
  z-index: -1;
}

@media (min-width: 768px) {
  .benefit-banner .banner-left:before {
      width: 65%;
  }
}

@media (min-width: 992px) {
  .benefit-banner .banner-left:before {
      width: 60%;
  }
}

@media (min-width: 1200px) {
  .benefit-banner .banner-left:before {
      width: 45%;
  }
}


/* main slider */

.text-slider{
  background-color: rgba(255, 255, 255, 0.7);
}





/* Stilizarea containerului imaginii */
.image-container {
  position: relative;
}

/* Stilizarea div-ului pentru text */
.text-overlay {
  position: absolute;
  top: 10px; /* Poziția verticală de sus */
  left: 10px; /* Poziția orizontală de la stânga */
  background-color: #E31E24; /* Fundal roșu */
  color: white; /* Text alb */
  padding: 5px 10px; /* Margini pentru text */
  border-radius: 10px;
 
  
}
