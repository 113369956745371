.my-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .my-modal img {
    object-fit: contain; /* Permite zoom pe imagine */
    width: 100%;
    height: 100%;
  }


  .left-button , .right-button {
    background-color: transparent;
    border: none;
    color:white;
    cursor: pointer;
  }

 .body{
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    padding-top: 2rem;
    position: absolute;
    top:0;
 }

  .close-modal{
    font-size: 2rem;
   cursor: pointer;
   color: #fff;
  }
 

  